import axios from "./axios"
import { logoutUser } from "../redux/slice/authSlice";
import env from "../helpers/baseURL";

const organizationID = env.organizationID;

export const getAllGames = async (query = undefined, dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem('user'))?.access_token;
    const url = token ? `/games` : `/games?organization_id=${organizationID}`;

    let config = {
      headers: {Authorization: `Bearer ${token}`}
    };

    if (typeof query !== 'object') {
      config.params = {...config.params, query};
    } else {
      config.params = query;
    }

    return await axios.get(url, config);

  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(logoutUser());
      return await axios.get(`/games?organization_id=${organizationID}`);
    }
    throw error;
  }
};
export const getGamesCategories = async () => {
  try {
    const token = JSON.parse(localStorage.getItem('user'))?.access_token;
    return await axios.get(token ? `/game-categories` : `/game-categories?organization_id=${organizationID}`, {
      headers: {Authorization: `Bearer ${token}`}
    });
  } catch (error) {
    if (error.response && error.response.status === 403) {
      return await axios.get(`/game-categories?organization_id=${organizationID}`);
    }
  }
};