import React, {useCallback, useEffect, useRef, useState} from 'react';
import SliderCustom from "../../Sliders/SliderCustom/SliderCustom";
import s from "./AllGame.module.scss";
import Button from "../../Button/Button";
import SliderGame from "../../Sliders/SliderGame/SliderGame";
import {useDispatch, useSelector} from "react-redux";
import {sizeBackgroundImage} from "../../../helpers/sizeBackgroundImage";
import {fetchGames} from "../../../redux/slice/gamesSlice";
import LoadingCustom from "../../loadingStates/LoadingCustom/LoadingCustom";

const PAGE_SIZE = 30;

const AllGame = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(state => state.auth.status);
  const gamesRedux = useSelector(state => state.games.games?.entities || []);
  const loading = useSelector(state => state.games.status) === 'loading';
  const totalGames = useSelector(state => state.games.games.count);
  const [page, setPage] = useState(1);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [displayedGames, setDisplayedGames] = useState(gamesRedux.length && gamesRedux.slice(0, PAGE_SIZE));

  useEffect(() => {
    if (gamesRedux.length < page * PAGE_SIZE) {
      if (gamesRedux.length < totalGames && !loading && page !== lastFetchedPage) {
        dispatch(fetchGames({query: {page}, isLazyLoading: true}));
        setLastFetchedPage(page);
      }
    } else {
      setDisplayedGames(gamesRedux.slice(0, page * PAGE_SIZE));
    }
  }, [page, dispatch, gamesRedux, loading, lastFetchedPage, totalGames]);

  const observer = useRef();

  const lastGameRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && displayedGames.length < totalGames) {
        setPage(prevPage => prevPage + 1);
      }
    }, {rootMargin: "100px"});

    if (node) observer.current.observe(node);
  }, [loading, displayedGames, totalGames]);

  useEffect(() => () => observer.current && observer.current.disconnect(), []);

  return (
    <>
      <SliderCustom/>
      {!displayedGames.length ? <LoadingCustom/> :
        <div className="container">
          <SliderGame name="Featured Games"/>
          <header className={s.header}>
            <h1 className={s.headerTitle}>All games</h1>
            <Button styled="secondary">All games</Button>
          </header>
          <div className={s.elementsContainer}>
            {displayedGames.length && displayedGames.map((game, index) => {
              const {link, width, height} = sizeBackgroundImage(game.images);
              const isLastItem = index === displayedGames.length - 1;

              return (
                <div
                  ref={isLastItem ? lastGameRef : null}
                  key={index}
                  className={s.element}
                  style={{
                    backgroundImage: `url(${link})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: `${width}px`,
                    height: `${height}px`,
                  }}
                >
                  <div className={s.elementHover}>
                    {authStatus === "succeeded"
                      ? <><Button styled="primary" onClick={() => window.open(game.links.play)}>Play</Button>
                        <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>
                      </>
                      : <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>}
    </>
  );
};

export default AllGame;
