import React from "react";
import styles from "./Divider.module.scss";

const Divider = (
  {
    background,
    margin,
    marginTop,
    marginBottom,
    height
  }
) => {
  return (
    <div
      style={{
        background: background || "#EAEAEA",
        marginTop: margin || marginTop || 0,
        marginBottom: margin || marginBottom || 0,
        height: height || 1
      }}
      className={styles.divider}
    />
  );
};

export default Divider;
