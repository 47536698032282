import React from 'react';
import s from "./LoadingCustom.module.scss"

const LoadingCustom = () => {
  return (
    <div className={s.boxesBg}>
      <div className={s.boxes}>
        <div className={s.box}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={s.box}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={s.box}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={s.box}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingCustom;