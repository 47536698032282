import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderGame.scss";
import Button from "../../Button/Button";
import {useSelector} from "react-redux";
import {randomAutoplaySpeedSlider} from "../../../helpers/randomAutoplaySpeedSlider";

const SliderGame = ({name}) => {
  const [slidesToShow, setSlidesToShow] = useState(2);
  const games = useSelector(state => state.games.games?.entities);
  const status = useSelector(state => state.auth.status);

  useEffect(() => {
    const match = window.matchMedia("(max-width: 576px)");
    const changeSlidesToShow = (e) => {
      setSlidesToShow(e.matches ? 1 : 2);
    };

    match.addListener(changeSlidesToShow);
    changeSlidesToShow(match);

    return () => match.removeListener(changeSlidesToShow);
  }, []);

  const selectRandomGames = (games, count) => {
    const shuffled = [...games].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomGames = games && selectRandomGames(games, 5);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: randomAutoplaySpeedSlider,
    pauseOnHover: true,
    arrows: false,
  };

  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
      <div className="slider-container pb-60 phw-60">
        <div className="slider-header">
          <h2 className="slider-title">{name}</h2>
          <div className="slider-buttons">
            <button type="button" className="prev" onClick={handlePrev}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <button type="button" className="next" onClick={handleNext}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <Slider ref={sliderRef} {...settings}>
          {randomGames && randomGames.map((game, index) => {
            return (
                <div key={index} className="slide-item">
                  <div className="slide-background">
                    <div
                        className="slide-photo"
                        style={{
                          backgroundImage: `url(${game.images[0].link})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          width: `96px`,
                          height: `96px`,
                        }}
                    />
                    <div className="slide-text">{game.name}</div>
                    {status === "succeeded"
                        ? <Button styled="primary" onClick={() => window.open(game.links.play)}>Play</Button>
                        : <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>}
                  </div>
                </div>)
          })}
        </Slider>
      </div>
  );
};

export default SliderGame;
