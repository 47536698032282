import React from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";

const MyFavoritesGame = () => {
  return (
    <div className="container">
      <Breadcrumbs name="My Favorites"/>
    </div>
  );
};

export default MyFavoritesGame;