import React from 'react';
import s from "../LeftSidebar.module.scss";
import dollar from "../../../assets/images/dolar.png";
import {Link} from "react-router-dom";

const Promotion = ({toggleSidebar}) => {
  return (
    <ul className={s.menuPromot}>
      <span className={s.titleMenu}><span>Promotions</span></span>
      <Link to="/bonus-50" onClick={toggleSidebar}>
        <li>
          <img src={dollar} alt=""/>
          <span>Bonus 50</span>
        </li>
      </Link>
      <Link to="/bonus-500" onClick={toggleSidebar}>
        <li>
          <img src={dollar} alt=""/>
          <span>Bonus 500</span>
        </li>
      </Link>
      <Link to="/persian-bonus" onClick={toggleSidebar}>
        <li>
          <img src={dollar} alt=""/>
          <span>Persian Bonus</span>
        </li>
      </Link>
    </ul>
  );
};

export default Promotion;