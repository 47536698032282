import React from 'react';
import s from "./BottomHeader.module.scss";
import classNames from "classnames";

const BottomHeader = ({isOpen, toggleSidebar}) => {
  const handleClick = () => {
    toggleSidebar();
  }

  return (
    <div className={classNames(s.bottomHeader)} onClick={handleClick}>
      <div>
        <div className={classNames(s.burger, isOpen && s.open1)}></div>
        <div className={classNames(s.burger, isOpen && s.open2)}></div>
        <div className={classNames(s.burger, isOpen && s.open3)}></div>
      </div>
    </div>
  );
};

export default BottomHeader;
