import React from 'react';
import SliderCustom from "../Sliders/SliderCustom/SliderCustom";
import SliderGame from "../Sliders/SliderGame/SliderGame";
import SliderTypeGame from "../Sliders/SliderTypeGame/SliderTypeGame";
import SlideGroupGame from "../Sliders/SlideGroupGame/SlideGroupGame";
import {useSelector} from "react-redux";
import LoadingCustom from "../loadingStates/LoadingCustom/LoadingCustom";
import styles from './MainContainer.module.scss';

const MainContainer = () => {
  const {games, status} = useSelector(state => state.games);

  return (
      <>
        <SliderCustom/>
        {games?.entities?.length ?
            <>
              <SliderGame name="Top games"/>
              <SliderTypeGame name="Casino games"/>
              {/*<SliderTypeGame name="Live Casino games"/>*/}
              <SlideGroupGame name="New Games"/>
            </>
            : status !== "succeededGames"
                ? <LoadingCustom/>
                : <div className={styles.emptyBlock}>Games not found</div>
        }
      </>
  );
};

export default MainContainer;
