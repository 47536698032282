import React from 'react';
import classNames from "classnames";
import s from "./Button.module.scss";

const Button = ({children, styled, className, onClick, forwardedRef, onKeyDown, disabled}) => {

  const classes = classNames(
    s.btn,
    {
      [s.primary]: styled === "primary",
      [s.secondary]: styled === "secondary",
      [s.withoutStyle]: styled === "withoutStyle",
      [s.disabled]: disabled
    },
    className
  );

  return (
    <button
      className={classes}
      onClick={onClick}
      ref={forwardedRef}
      onKeyDown={onKeyDown}
      disabled={disabled}
    >
      {children}
    </button>
  );

};

export default Button;
