import React from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Bonus from "../Bonus/Bonus";
import bonus from "../../../assets/images/commission.png";

const PersianBonus = () => {
  const title = "Introducing the \"Personal Bonus\" Promotion!"
  const label = "We are delighted to announce our latest promotion, the \"Personal Bonus\", where you have the opportunity to receive a refund of 10% of the total amount you spend."
  const participation = "Participation: To participate in the \"Personal Bonus\" promotion, simply top up on our platform. Every time you top money up, you become eligible for the bonus refund."
  const calculation = "Calculation: The refund amount is calculated as 10% of the total amount you spend during the promotion period. For example, if you spend 100, you will receive a refund of 10 as a bonus."
  const bonusDistribution = "Bonus Distribution: After the promotion period ends, we will credit your account with the calculated refund amount."
  const eligibility = "Eligibility: All customers who top money up are eligible for the \"Personal Bonus\" refund. There are no additional registration requirements or fees to participate."
  const promotionDuration = "Promotion Duration: The \"Personal Bonus\" promotion will run for a specific period, as mentioned in our promotional materials. Make sure to take advantage of this offer during the designated timeframe."
  const termsAndConditions = "Terms and Conditions: Please familiarize yourself with the complete terms and conditions of the \"Personal Bonus\" promotion. These conditions outline the eligibility criteria, refund limitations, and any other relevant information. You can find the detailed terms and conditions by contacting our customer support."
  const info = "Don't miss out on the chance to receive a generous refund of 10% on your total spending! Top up you account now and enjoy the benefits of the \"Personal Bonus\" promotion."
  const prevClick = "/bonus-50"
  const nextClick = "/bonus-500"
  const prevBonus = (
    <div>
      Bonus 50
      <div>
        <p className="bonusParagraph">We are thrilled to announce our exciting new promotion, "Bonus 50", where we're giving away a bonus prize of 50 UAH to all registered customers.</p>
      </div>
    </div>
  );
  const nextBonus = (
    <div>
      Bonus 500
      <div>
        <p className="bonusParagraph">We are excited to present our latest promotion, "Bonus 500," where we are offering a generous prize of 500 UAH (Ukrainian hryvnia) to all registered customers who deposit an equal amount into their accounts.</p>
      </div>
    </div>
  );

  return (
    <div className="container">
      <Breadcrumbs name="Persian bonus"/>
      <Bonus
        img={bonus}
        title={title}
        label={label}
        participation={participation}
        calculation={calculation}
        bonusDistribution={bonusDistribution}
        eligibility={eligibility}
        promotionDuration={promotionDuration}
        termsAndConditions={termsAndConditions}
        info={info}
        prevClick={prevClick}
        nextClick={nextClick}
        prevBonus={prevBonus}
        nextBonus={nextBonus}
      />
    </div>
  );
};

export default PersianBonus;