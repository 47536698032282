import axios from "./axios"

export const login = async (params) => {
  try {
    return await axios.post(`/login`, params)
  } catch (error) {
    throw error.response.data.data.message
  }
};

export const emailVerification = async (params) => {
  try {
    return await axios.get(`/login`, {params})
  } catch (error) {
    throw error
  }
};