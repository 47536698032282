import React from 'react';
import s from "./LegalPage.module.scss";
import classNames from "classnames";

const LegalPage = ({title, children}) => {
  let content;
  if (title === "Privacy Policy") {
    content = "GENERAL PROVISIONS\n" +
      "As the data controller, we collect information from the Client in order to provide them with access to the services of the Website.\n" +
      "The purpose of this notice is to inform you about our policy regarding the collection, use, and disclosure of personal information. Your information will not be shared with anyone except the individuals specified in this document.\n" +
      "We reserve the right to update the Privacy Policy at any time without prior notice. We will always process data in accordance with the Privacy Policy to which you have agreed. In the event of any changes to the Policy, we will notify you on the Website. Your continued use of the Website and/or its services will signify your agreement to the updated Policy.\n" +
      "When we collect information: during your visit to our website, such as during registration or when subscribing to informational newsletters.\n" +
      "How we use the information: in compliance with legal obligations, to improve our Website, and to prevent fraud.\n" +
      "The information we collect: name, date of birth, phone number, data provided by you when contacting us, particularly through contact or subscription forms on our Website, data you have sent us through online chat, self-exclusion data. This is done to improve our website and the services provided.\n" +
      "1. PURPOSE OF PERSONAL DATA PROCESSING To provide you with the services you are seeking on our website. To improve our website and services. To ensure the security of our website. To enhance overall market-relevant content using anonymous or pseudonymized personal data. And if you have additionally agreed to receive newsletters, for example, via email, phone, SMS. None of the above points imply automated decision-making.\n" +
      "2. CATEGORIES OF PERSONAL DATA Personal data refers to any information relating to an identified individual. We collect, process, and use the following personal data:\n" +
      "Name, email address, date of birth, phone number, gender. Other data provided by you when contacting us, particularly through contact forms or registration forms on our website. Data you have sent us through online chat, phone calls, and support inquiries. Self-exclusion data. This is done to improve our website and the services provided.\n" +
      "3. TECHNICAL AND ORGANIZATIONAL DATA PROTECTION \n" +
      "We implement possible technical and organizational measures to protect your personal data from misuse and loss.\n" +
      "4. YOUR RIGHTS \n" +
      "You have the right to request information about the personal data we store about you. If your personal data is incorrect, you can correct it. You can object to the processing of your personal data and withdraw your consent.\n" +
      "5. CONTACT \n" +
      "Name: MegaWinClub Contact information is provided on the website."
  } else {
    content = "TERMS AND CONDITIONS\n" +
      "INTRODUCTION\n" +
      "These rules and conditions, as well as the documents mentioned below (\"Terms\"), apply to the use of the current website (\"Website\") and its associated or connected services (\"Service\").\n" +
      "You should carefully read these Terms as they contain important information regarding your rights and obligations regarding the use of the Website and constitute a binding legal agreement between you - our client (\"Client\") - and us. By using this Website and/or accessing the Service, you, whether as a guest or a registered user with an account (\"Account\"), agree to comply with these Terms, including any amendments that may be published later. If you do not agree to these Terms, you should refrain from accessing the Service and using the Website.\n" +
      "The Service is owned by a limited liability company.\n" +
      "2. GENERAL CONDITIONS\n" +
      "We reserve the right to review and modify the Terms (including any documents referenced below) at any time. You should periodically visit this page to review the Terms of use. The changes will become binding and effective immediately upon publication on this Website. If you object to certain changes, you must immediately cease using the Service. Continued use of the Website after such publication indicates your agreement to the amended Terms. Any bets not settled before the modified Terms take effect shall be subject to the previous Terms.\n" +
      "3. YOUR OBLIGATIONS\n" +
      "You acknowledge that while accessing the Website and using the Service:\n" +
      "3.1. You are at least 18 years old. We reserve the right to verify documents confirming your age at any time.\n" +
      "3.2. You have legal capacity and can enter into a binding agreement with us. You cannot access the Website or use the Service if you lack legal capacity.\n" +
      "3.3. You are fully responsible for ensuring that the use of the Service is lawful.\n" +
      "3.4. You are an authorized user of the payment method you use.\n" +
      "3.5. You bear full responsibility for the deposits made.\n" +
      "3.6. You do not act on behalf of another party or for any commercial purposes, but solely in your own name as a private individual.\n" +
      "4. LIMITED USE\n" +
      "4.1. You must not use the Service:\n" +
      "4.1.1. If you are under 18 years old (or below the legal age as prescribed by the laws of your country) or if you lack the legal capacity to enter into a binding agreement with us or if you act as an agent (or otherwise) on behalf of a person under the age of 18 (or below the legal age as prescribed by the laws of your country);\n" +
      "4.1.2. To disrupt or improperly interfere with the activities of other Clients or the functioning of the Service as a whole;\n" +
      "4.1.3. To advertise unwanted commercial advertisements, affiliate links, and other forms of imposed services that may be removed from the Service without prior notice;\n" +
      "4.1.4. Any effort that, in our reasonable opinion, may be considered an attempt to: (i) deceive the Service or another Client using the Service, or (ii) conspire with any other Client using the Service to gain unfair advantage;\n" +
      "4.1.5. To engage in any unlawful activities;\n" +
      "4.2. You are not allowed to sell or transfer your account to third parties, nor can you acquire an account from a third party.\n" +
      "5. REGISTRATION\n" +
      "By using the Service, you agree that:\n" +
      "5.1. We reserve the right to reject any registration application at our own discretion and without any obligation to provide specific reasons.\n" +
      "5.2. Prior to using the Service, you must undergo registration. To start using the Service or making withdrawals, we may require you to become a verified Client, which involves undergoing certain checks. You may be required to provide valid proof of identity and any other documents deemed necessary. This includes, but is not limited to, a photo ID (copy of passport, driver's license, or national ID card). We reserve the right to limit the account parameters on any account until the necessary information is obtained.\n" +
      "5.3. You are allowed to register only one account in the Service. Accounts will be promptly closed if it is discovered that you have multiple accounts registered with us. This includes the use of representatives, relatives, partners, affiliates, related parties, individuals, and/or third parties acting on your behalf.\n" +
      "5.4. You must keep your password for the Service confidential. Provided that the requested account information has been provided correctly, we may assume that all operations have been conducted by you. We recommend that you regularly change your password and never disclose it to third parties. You are responsible for safeguarding your password, and any failure to do so is at your own risk and expense. You can log out of the Service at the end of each session. If you believe that any information of your account has been misused by a third party, or if your account has been compromised or your password has been discovered by a third party, we will immediately suspend your account once notified. In the meantime, you are responsible for all activities in your account, including third-party access, whether or not you authorized such access.\n" +
      "5.5. We are not obliged to open an account for you, and our registration page on the website is only an invitation to register. We may, at our sole discretion, decide to confirm or refuse the registration of your account, and if we refuse to open an Account for you, we are not obligated to provide you with the reasons for such refusal.\n" +
      "5.6. After receiving your application, we may contact you to obtain additional information and/or documentation for compliance with our regulatory and legal obligations.\n" +
      "6. WITHDRAWAL OF FUNDS\n" +
      "6.1. You may withdraw any unused funds available in your account for immediate use by submitting a withdrawal request in accordance with our withdrawal terms. The minimum and maximum withdrawal amounts are specified separately.\n" +
      "6.2. There are no withdrawal fees.\n" +
      "6.3. We reserve the right to request photo identification, proof of address, or perform additional verification procedures (such as requesting your selfie, organizing a call for verification, etc.) to verify your identity before allowing any withdrawals from your account.\n" +
      "6.4. All withdrawals must be made to the original debit/credit card or payment method used to make the deposit into your account. We may, at our sole discretion, allow you to withdraw funds using a payment method from which your initial deposit was not made. This will always be subject to additional security checks.\n" +
      "6.5. Please note that we cannot guarantee successful processing of withdrawals or refund of funds in case you violate the restricted usage policy stated in sections 3.3 and 4.\n" +
      "6.6. Withdrawals are processed within 24 business hours. The company is not responsible for any delays in payment processing that may occur after the request has been processed.\n" +
      "ERRORS\n" +
      "7.1. In the event of an error or malfunction in our system or electronic payment processors, you are obligated to promptly notify us upon discovering any errors in the Service. In the event of communication or system errors arising from the use of the Service and/or payments made to you as a result of a defect or error in the Service, we will rectify the errors and resolve any direct or indirect expenses, damages, or claims arising from or as a result of such errors during normal working hours.\n" +
      "7.2. We make every effort to ensure the avoidance of errors.\n" +
      "8. INFORMATION AND NOTIFICATIONS\n" +
      "8.1. All information and notifications that you are required to provide us in accordance with these Terms must be submitted using the customer support form.\n" +
      "8.2. All information and notifications that we provide to you in accordance with these Terms will be sent to the contact details you have provided, which we have on record in our system for each respective client. The method of such communication is at our sole discretion.\n" +
      "8.3. All information and notifications that you or we are required to provide in accordance with these Terms must be provided in writing.\n" +
      "8.4. From time to time, we may contact you and/or communicate with you via SMS to provide you with information, unique offers, and other updates. By agreeing to these Terms during registration on the website, you consent to receiving such emails/SMS.\n" +
      "9. MATTERS BEYOND OUR CONTROL\n" +
      "We shall not be liable for any failure or delay in providing the Service due to circumstances beyond our control, which can reasonably be considered as being outside of our control, despite us taking reasonable preventive measures, such as acts of God, trade or labor disputes, power outages, telecommunication service disruptions or failures, or any other delay or failure caused by a third party. We shall not be responsible for any losses or damages you may incur. In such a case, we reserve the right to cancel or suspend the provision of the Service without any liability.\n" +
      "10. LIABILITY\n" +
      "10.1. To the extent permitted by applicable law, we shall not be liable for any foreseeable damages (direct or indirect) that you may incur if we fail to fulfill our obligations under these terms, unless we breach any duties imposed on us by law (in particular, if we cause death or personal injury through our negligence), and in such a case, we shall not be liable to you if the failure is attributable to any of the following: (i) your own fault; (ii) a third party unrelated to the performance of these terms (e.g., issues related to network performance, congestion and connectivity, or the operation of your computer equipment); or (iii) any other events that neither we nor our suppliers could have foreseen or prevented, even if we or they exercised due care. As this Service is intended for consumers only, we shall not be liable for any commercial losses.\n" +
      "10.2. In the event that we are liable for any event under these terms, our total aggregate liability to you under or in connection with these terms shall not exceed (a) the value of the bets and/or wagers you have made through your account regarding the relevant bet/wager or product giving rise to the relevant liability, or (b) EUR 500 in total, whichever is less.\n" +
      "10.3. We strongly recommend that you (i) verify the suitability and compatibility of the Service with your own computer equipment before use, and (ii) take reasonable precautions to protect yourself against harmful programs or devices, including the installation of antivirus software.\n" +
      "11. COMPLAINTS\n" +
      "11.1. If you have any doubts or questions regarding these Terms, you should contact our customer service department using the links provided on the website and use your registered email address to communicate with us.\n" +
      "11.2. If a Client is dissatisfied with the way a service has been provided, the Client should provide detailed information about their complaint to our customer service department. We will make all reasonable efforts to respond to such requests within a few days (in any case, we intend to respond to all such requests within 28 days of receipt).\n" +
      "11.3. Complaints must be submitted within three (3) days from the date of the occurrence of the complaint issue. No claims will be considered after this period.\n" +
      "11.4. In the event of a dispute between you and us, our customer service department will attempt to reach a mutually agreed resolution. If our customer service department is unable to reach a mutually agreed resolution with you, the matter will be escalated to our management.\n" +
      "12. SEVERABILITY OF CONTRACT PROVISIONS\n" +
      "In the event that any provision of these Terms is determined by any competent authority to be invalid, unenforceable, or without legal effect, the relevant provision shall be modified to give it full effect and enforceability to the extent permitted by applicable law. This does not affect the validity and legal force of the remaining provisions of these Terms.\n" +
      "13. VIOLATION OF THESE TERMS\n" +
      "Without prejudice to any other legal remedies available to us, we may suspend or terminate the operation of your account and refuse to continue providing you with the Service, in any case without prior notice, if, in our reasonable opinion, you violate any material provision of these terms. However, notice of any such actions will be promptly provided to you.\n" +
      "14. GENERAL PROVISIONS\n" +
      "14.1. Term of the Agreement. These Terms shall remain in full force and effect as long as you have access to, or use the Service, or are a Client or visitor of the Website. These Terms shall survive the termination of your account for any reason.\n" +
      "14.2. Waiver. Our failure, whether as a result of conduct or otherwise, to enforce or exercise any provision of these Terms or to respond to a breach or threat of a breach by you of any provision of these Terms shall not operate or be construed as a waiver against us and shall not be binding upon us unless made in writing and properly signed by us, and, if the submission to a written waiver is limited to specific provisions, from which the waiver was made. Our inability at any time to enforce any provision of these Terms shall not be deemed a waiver of such provision or the right to enforce such provision at any other time.\n" +
      "14.3. Acknowledgement. By continuing to access or use the Service, you acknowledge that you have read, understood, and agreed to each provision of these Terms. Therefore, you irrevocably waive any future arguments, claims, demands, or contrary actions contained in these Terms.\n" +
      "14.4. Entire Agreement. These Terms constitute the entire agreement between you and us regarding your access to the Service and use of the Service, and supersede all prior agreements and communications, whether oral or written, concerning the subject matter of these Terms."
  }

  return (
    <div className={s.wrap}>
      <div className={classNames(s.backgroundContainer,"container")}>
        <div className={s.contentBlock}>
          <h1>{title}</h1>
          {content.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          {children}
        </div>
      </div>
    </div>
  );
};

export default LegalPage;
