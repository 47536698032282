import React, {useState, useRef, useEffect} from 'react';
import s from "./CustomSelect.module.scss";
import {ReactComponent as ArrowDown} from '../../assets/icons/arrow-down.svg';
import {ReactComponent as ArrowUp} from '../../assets/icons/arrow-up.svg';
import chcLogo from '../../assets/images/chc_logo.png';

const CustomSelect = ({items, placeholder, onSelectChange, currentAccount}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const node = useRef();

  useEffect(() => {
    const defaultItem = currentAccount && items.find(({account}) => account === currentAccount);
    setSelectedItem(defaultItem);
    onSelectChange && onSelectChange(defaultItem);
  }, [currentAccount, items]);

  const handleClickOutside = ({target}) => {
    if (node.current.contains(target)) return;
    setIsOpen(false);
  };

  const handleOptionClick = item => {
    setSelectedItem(item);
    onSelectChange && onSelectChange(item);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={s.selectWrap} ref={node}>
      <div className={s.select} onClick={() => setIsOpen(!isOpen)}>
        <div className={s.selectCurrent}>
          {selectedItem ? (
            <>
              {selectedItem.provider === 'champion' && <img src={chcLogo} alt="chc logo" style={{marginRight: '5px'}}/>}
              {selectedItem?.account && selectedItem.account.match(/.{1,2}/g).join('-')}
              <span style={{margin: '0 30px'}}>-</span>
              {selectedItem.amount}
            </>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>
        {isOpen ? <ArrowUp className={s.arrowIcon}/> : <ArrowDown className={s.arrowIcon}/>}
      </div>
      {isOpen && (
        <ul className={s.dropdown}>
          {items.map((item, index) => (
            <li key={index} onClick={() => handleOptionClick(item)}>
              {item.provider === 'champion' && <img src={chcLogo} alt="chc logo" style={{marginRight: '5px'}}/>}
              {item?.account && item.account.match(/.{1,2}/g).join('-')}
              <span style={{margin: '0 30px'}}>-</span>
              {item.amount}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;


