import React, {useEffect, useRef, useState} from 'react';
import s from "./WithdrawPopUp.module.scss";
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import {useDispatch, useSelector} from "react-redux";
import Divider from "../../Divider/Divider";
import CustomSelect from "../../CustomSelect/CustomSelect";
import Button from "../../Button/Button";
import {updateAccounts} from "../../../services/payment";
import master from "../../../assets/images/mastercard_86833.png"
import visa from "../../../assets/images/visa-card.png"
import otherCard from "../../../assets/images/otherCardIcon.jpeg"
import bank1 from "../../../assets/icons/monoBank.svg"
import bank2 from "../../../assets/images/privatBank.png"
import bank3 from "../../../assets/icons/a-bank.svg"
import bank4 from "../../../assets/icons/otherBank.svg"
import classNames from "classnames";
import {updateInvoices} from "../../../redux/slice/authSlice";
import axios from "../../../services/axios";
import {bankBinCodes} from "./bankBinCodes";

const WithdrawPopUp = ({data, onClose, currentAccount}) => {
  const dispatch = useDispatch();
  const popupContentRef = useRef();
  const user = useSelector(state => state.auth?.user?.user);
  const [sumValue, setSumValue] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errorCard, setErrorCard] = useState(null)
  const [errorSum, setErrorSum] = useState(null)
  const [errorWithdraw, setErrorWithdraw] = useState(null)
  const [cardBank, setCardBank] = useState("");
  const [bank, setBank] = useState("");
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);

  const handleWithdrawAll = () => {
    if (selectedAccount) {
      setSumValue(selectedAccount.amount);
    }
  };

  const handleAccountChange = (account) => {
    setSelectedAccount(account);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    updateAccounts(user.access_token).then((res) => {
      if (res?.data) {
        dispatch(updateInvoices(res.data));
      }
    });
  }, [withdrawSuccess])

  const bankIcons = {
    monobank: bank1,
    privatBank: bank2,
    aBank: bank3,
    bank4: bank4
  }
  const banks = [
    {value: 'monobank', label: 'Mono bank', icon: bank1},
    {value: 'privatBank', label: 'Privat bank', icon: bank2},
    {value: 'aBank', label: 'A-Bank', icon: bank3},
    {value: 'bank4', label: 'Other bank', icon: bank4},
  ];
  const detectCardType = (number) => {
    const sanitizedNumber = number.replace(/\s/g, '');
    if (/^4[0-9]{5,}$/.test(sanitizedNumber)) {
      return "visa";
    } else if (/^5[1-5][0-9]{4,}$/.test(sanitizedNumber)) {
      return "mastercard";
    } else {
      return "other";
    }
  };

  const handleCardInputChange = async (e) => {
    setErrorCard(null)
    let value = e.target.value.replace(/\D/g, "");
    value = value.slice(0, 16);
    const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
    setCardBank(formattedValue);
    const detectedBank = detectBank(formattedValue);
    setBank(detectedBank);
    if (value.length === 16) {
      const isValidCard = await validateCardNumber(value);
      if (!isValidCard) {
        setErrorCard('Invalid card number');
      }
    }
  };

  const handleBankChange = (e) => {
    setBank(e.target.value);
  };

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, "");
    const inputValue = Number(e.target.value);
    setErrorSum(inputValue > selectedAccount?.amount);
    value = value.replace(/^0+/, "");
    if (value === "") {
      setSumValue(0);
    } else {
      setSumValue(value);
    }
    if (Number(value) > user.payments[0].maximum_amount && user.payments[0].maximum_amount !== 0) {
      setError(`Maximum amount is ${user.payments[0].maximum_amount} UAH`);
    } else {
      if (user.payments.length === 0) {
        setError(`Payments getaway not found`);
      } else {
        setError(null)
      }
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      const response = await axios.post('/payments/withdraw', {
        invoice_id: currentAccount.id,
        amount: Number(sumValue),
        card: cardBank.replace(/\s/g, ''),
        bank: bank,
      }, {
        headers: {Authorization: `Bearer ${user?.access_token}`}
      });
      setWithdrawSuccess(true);
      setErrorWithdraw(null);
    } catch (error) {
      if (error.response) {
        setErrorWithdraw(error.response.data.message || 'There was a withdrawal error');
      } else {
        setErrorWithdraw('There was a withdrawal error');
      }
    } finally {
      setLoading(false);
    }
  };
  const detectBank = (cardNumber) => {
    const bin = cardNumber.replace(/\s/g, '').slice(0, 6);

    for (const [bank, codes] of Object.entries(bankBinCodes)) {
      if (codes.some(code => bin.startsWith(code.toString()))) {
        return bank;
      }
    }
    return 'bank4';
  };

  const validateCardNumber = async (cardNumber) => {
    try {
      const response = await axios.get(`https://lookup.binlist.net/${cardNumber}`);
      if (response.data) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <div className={s.overlay}>
      <div className={s.popupContent} ref={popupContentRef}>
        <div className={s.head}>
          <Close className={s.iconClose} onClick={onClose}/>
          <h2>Withdraw you account</h2>
          <span>Please fill the form</span>
          <Divider marginTop={7}/>
        </div>
        {withdrawSuccess
          ? <div className={s.successMessage}>
            <p>Congratulations, you have successfully withdrawn</p>
            <p>{sumValue} UAH to your bank card.</p>
            <Button styled="primary" onClick={onClose}>Close</Button>
          </div>
          : <>
            <span>Chose you account</span>
            <CustomSelect
              currentAccount={currentAccount?.account}
              items={data.player?.invoices}
              onSelectChange={handleAccountChange}
            />
            <span className={s.enterSumStyle}>Enter sum for withdrowing</span>
            <div className={s.enterSum}>
              <input
                value={sumValue}
                onChange={handleChange}
                disabled={loading}
              />
              <Button styled="primary" onClick={handleWithdrawAll} disabled={loading || error}>Withdraw all</Button>
            </div>
            {errorSum && (
              <span className={s.errorStyle}>Withdrawal amount exceeds account balance.</span>
            )}
            <span>Minimum amount 100 uah</span>
            <span className={s.enterSumStyle}>Enter you bank card</span>
            <div className={s.inputBankWrapper}>
              <input
                className={s.inputBank}
                value={cardBank}
                onChange={handleCardInputChange}
                disabled={loading}
                maxLength="19"
              />
              {cardBank.length > 0 && (
                <img
                  src={detectCardType(cardBank) === 'visa' ? visa : detectCardType(cardBank) === 'mastercard' ? master : otherCard}
                  alt="Card Icon"
                  className={classNames(s.cardIcon, {[s.bankIconMono]: detectCardType(cardBank) === "visa"})}/>
              )}
            </div>
            {errorCard && <span className={s.errorStyle}>Invalid card number</span>}
            <span className={s.enterSumStyle}>Chose you bank</span>
            <div className={s.bankBlockIcon}>
              <select
                value={bank}
                onChange={handleBankChange}
                className={s.bankSelect}
                disabled={loading || bank !== 'bank4' || error}
              >
                <option value="" disabled>Select your bank</option>
                {banks.map((bank) => (
                  <option value={bank.value} key={bank.value}>
                    <img src={bank.icon} alt={`${bank.label} Icon`} className={s.bankIcon}/> {bank.label}
                  </option>
                ))}
              </select>
              {bank && (
                <img src={bankIcons[bank]} alt="Bank Icon"
                     className={classNames(s.cardIcon, {[s.bankIconABank]: bank === "aBank"})}/>
              )}
            </div>
            {errorWithdraw ? (
              <span className={s.errorStyle}>{errorWithdraw}</span>
            ) : (
              <Button
                styled="primary" o
                onClick={() => handleWithdraw()}
                disabled={loading || cardBank.trim().length !== 19 || errorCard || error || sumValue < 100 || sumValue > selectedAccount?.amount}>
                Send
              </Button>
            )}
            <p className={s.footerTopUp}>If you have questions, please contract to <span>support</span></p>
          </>}
      </div>
    </div>
  );
};

export default WithdrawPopUp;