import React, {useState} from 'react';
import s from './GameType.module.scss';
import slots from '../../../assets/images/slots.png';
import roulette from '../../../assets/images/roulette.png';
import poker from '../../../assets/images/poker.png';
import dice from '../../../assets/images/dice.png';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../loadingStates/loadingCategory/Table";
import {clearCategoryFilters} from "../../../redux/slice/gamesSlice";

const GameType = ({toggleSidebar}) => {
  const categories = useSelector(state => state.games?.categories);
  const [lastClickedId, setLastClickedId] = useState(null);
  const dispatch = useDispatch();
  const iconMap = {
    "slots": slots,
    "roulette": roulette,
    "poker": poker,
    "dice": dice
  };
  const routeMap = {
    "slots": "/slots",
    "roulette": "/roulette",
    "poker": "/poker",
    "dice": "/dice"
  };

  return (
    categories.length ?
      <ul className={s.menuGameType}>
        {categories.map((category) => {
          const iconName = category.icon.replace(/[:\s]/g, "");
          return (
            <Link key={category.id} to={routeMap[iconName]} onClick={toggleSidebar}>
              <li onClick={(e) => {
                if (lastClickedId !== category.id) {
                  dispatch(clearCategoryFilters());
                  setLastClickedId(category.id);
                }
              }}>
                <img src={iconMap[iconName]} alt=""/>
                <span>{category.name}</span>
              </li>
            </Link>)
        })}
      </ul> : <Table length={1}/>
  );
};

export default GameType;
