import React from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Bonus from "../Bonus/Bonus";
import bonus from "../../../assets/images/bonus.png"

const Bonus500 = () => {
  const title = "Introducing \"Bonus 500\" Promotion!"
  const label = "We are excited to present our latest promotion, \"Bonus 500,\" where we are offering a generous prize of 500 UAH (Ukrainian hryvnia) to all registered customers who deposit an equal amount into their accounts."
  const registration = "Registration: To take advantage of the \"Bonus 500\" promotion, you need to register as a customer on our platform. The registration process is quick, simple, and absolutely free!"
  const accountDeposit = "Account Deposit: After completing the registration, you can proceed to deposit 500 UAH i"
  const prizeDistribution = "Prize Distribution: Once you have successfully registered and made a qualifying deposit, we will credit your account with a bonus amount of 500 UAH. These funds can be used for various purposes, such as making purchases or withdrawing as per our terms and conditions."
  const timeLimitedOffer = "Time-Limited Offer: The \"Bonus 500\" promotion is only available for a limited time. To ensure you don't miss out on this opportunity, make sure to register and deposit the qualifying amount before the deadline."
  const termsAndConditions = "Terms and Conditions: Please note that the \"Bonus 500\" promotion is subject to certain terms and conditions. These conditions outline the eligibility criteria, usage limitations, and withdrawal policies. We strongly encourage you to review and understand the complete terms and conditions, which can be found on our support."
  const info = "Don't miss this incredible chance to receive a bonus prize of 500 UAH! Register now, deposit the qualifying amount, and enjoy the added benefits of being a part of our esteemed customer community."
  const prevClick = "/bonus-50"
  const nextClick = "/persian-bonus"

  const prevBonus = (
    <div>
      Bonus 50
      <div>
        <p className="bonusParagraph">We are thrilled to announce our exciting new promotion, "Bonus 50", where we're
          giving away a bonus prize of 50 UAH to all registered customers.</p>
      </div>
    </div>
  );
  const nextBonus = (
    <div>
      Persian Bonus
      <div>
        <p className="bonusParagraph">We are delighted to announce our latest promotion, the "Personal Bonus", where you
          have the opportunity to receive a refund of 10% of the total amount you spend.</p>
      </div>
    </div>
  );

  return (
    <div className="container">
      <Breadcrumbs name="Bonus 500"/>
      <Bonus
        title={title}
        label={label}
        img={bonus}
        registration={registration}
        accountDeposit={accountDeposit}
        prizeDistribution={prizeDistribution}
        timeLimitedOffer={timeLimitedOffer}
        termsandConditions={termsAndConditions}
        info={info}
        prevBonus={prevBonus}
        nextBonus={nextBonus}
        prevClick={prevClick}
        nextClick={nextClick}
      />
    </div>
  );
};

export default Bonus500;