import React from 'react';
import s from "./LeftSidebar.module.scss";
import Promotion from "./Menu/Promotion";
import FilterMenu from "./Menu/FilterMenu";
import Divider from "../Divider/Divider";
import classNames from "classnames";
import GameType from "./Menu/GameType";

const LeftSidebar = ({isOpen, toggleSidebar}) => {
  return (
    <>
      <nav className={classNames(s.menu, {[s.menuOnTop]: isOpen})}>
        <Promotion toggleSidebar={isOpen ? toggleSidebar : undefined}/>
        <FilterMenu toggleSidebar={isOpen ? toggleSidebar : undefined}/>
        <Divider marginBottom={15}/>
        <GameType toggleSidebar={isOpen ? toggleSidebar : undefined}/>
        <Divider marginTop={60}/>
      </nav>
      <div className={s.sidebarOverlay} onClick={toggleSidebar}></div>
    </>
  );
};

export default LeftSidebar;