import React from 'react';
import s from "./Bonus.module.scss"
import Divider from "../../Divider/Divider";
import {useNavigate} from "react-router-dom";


const Bonus = (
  {
    img,
    title,
    label,
    registration,
    eligibility,
    prizeDistribution,
    limitedTimeOffer,
    TermsAndConditions,
    info,
    accountDeposit,
    timeLimitedOffer,
    participation,
    calculation,
    bonusDistribution,
    promotionDuration,
    termsAndConditions,
    prevBonus,
    nextBonus,
    prevClick,
    nextClick
  }
) => {
  const navigate = useNavigate()

  const handleClickPrev = () => {
    navigate(prevClick);
  }
  const handleClickNext = () => {
    navigate(nextClick);
  }
  return (
    <div className={s.wrap}>
      <div className="container">
        <div className={s.blockBonus}>
          <div className={s.blockBonusHeader}>
            <img className={s.img} src={img} alt=""/>
            <div className={s.blockBonusHeaderTitle}>
              <h2>{title}</h2>
              <p className={s.labelBig}>{label}</p>
            </div>
          </div>

          <div>
            <p className={s.labelSmall}>{label}</p>
            <h4 className={s.titleText}>Here's how it works:</h4>
            <p>{registration || participation}</p>
            <p>{eligibility || accountDeposit}</p>
            <p>{calculation}</p>
            <p>{prizeDistribution || bonusDistribution}</p>
            <p>{limitedTimeOffer || timeLimitedOffer || eligibility || promotionDuration}</p>
            <p>{TermsAndConditions || termsAndConditions}</p>
            <p>{info}</p>
          </div>
        </div>
        <Divider background="#531A99" height={10} marginTop={20} marginBottom={25}/>

        <div className={s.routeBonus}>
          <div className={s.routeBonusBlock} onClick={handleClickPrev}>
            {prevBonus}
          </div>
          <div className={s.routeBonusBlock} onClick={handleClickNext}>
            {nextBonus}
          </div>
        </div>

      </div>

    </div>
  );
};

export default Bonus;