import React, {useEffect, useRef, useState} from 'react';
import s from "./TopUpAccountPopUp.module.scss"
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import Divider from "../../Divider/Divider";
import CustomSelect from "../../CustomSelect/CustomSelect";
import Button from "../../Button/Button";
import {sendPayment} from "../../../services/payment";
import {useSelector} from "react-redux";


const TopUpAccountPopUp = ({data, onClose, currentAccount}) => {
  const popupContentRef = useRef();
  const user = useSelector(state => state.auth?.user?.user);
  const [sumValue, setSumValue] = useState(user.payments[0].minimum_amount)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (user.payments.length === 0) {
      setError(`Payments getaway not found`);
    }
  }, [])

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, "");
    value = value.replace(/^0+/, "");
    if (value === "") {
      setSumValue(0);
    } else {
      setSumValue(value);
    }
    if (Number(value) > user.payments[0].maximum_amount && user.payments[0].maximum_amount !== 0) {
      setError(`Maximum amount is ${user.payments[0].maximum_amount} UAH`);
    } else {
      if (user.payments.length === 0) {
        setError(`Payments getaway not found`);
      } else {
        setError(null)
      }
    }
  };

  const handleTopUpAccount = async () => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const body = {
        invoice_id: currentAccount.id,
        amount: Number(sumValue),
        amount_currency: 'UAH',
      };
      const result = await sendPayment(user.user?.payments?.find(p => p.name === 'payonhost')?.id, body, user?.access_token);
      if (result.data?.url) {
        // window.location.href = result.data.url;
        window.open(result.data.url, '_blank');
      }
      onClose()
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
    setLoading(false);
  }

  return (
    <div className={s.overlay}>
      <div className={s.popupContent} ref={popupContentRef}>
        <div className={s.head}>
          <Close className={s.iconClose} onClick={onClose}/>
          <h2>Top up you account</h2>
          <span>Please fill the form</span>
          <Divider marginTop={7}/>
        </div>
        <span>Chose you account</span>
        <CustomSelect currentAccount={currentAccount?.account} items={data.player?.invoices}/>
        <span>Enter sum</span>
        <div className={s.enterSum}>
          <input
            value={sumValue}
            onChange={handleChange}
            disabled={loading}
          />
          <Button styled="primary" onClick={() => handleTopUpAccount()} disabled={loading || error}>Pay</Button>
        </div>
        {error && <div className={s.errorStyle}>{error}</div>}

        {loading && <div className={s.loadingStyle}>Loading...</div>}
        {user.payments[0].minimum_amount > 0 && <span>Minimum amount {user.payments[0].minimum_amount} uah</span>}
        <p className={s.footerTopUp}>If you have questions, please contract to <span>support</span></p>
      </div>
    </div>
  );
};

export default TopUpAccountPopUp;