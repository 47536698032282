import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import services from "../../services";
import {fetchGames} from "./gamesSlice";

export const loginUser = createAsyncThunk('auth/loginUser', async ({username, password}, thunkAPI) => {
  const response = await services.login({username, password});
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
    thunkAPI.dispatch(fetchGames());
  }
  return response.data;
});

export const registerUser = createAsyncThunk('auth/registerUser', async (params) => {
  const response = await services.register(params);
  return response.data;
});

export const emailVerification = createAsyncThunk('auth/emailVerification', async (params) => {
  const response = await services.emailVerification(params);
  return response.data;
});

export const loadUserFromLocalStorage = createAsyncThunk('auth/loadUserFromLocalStorage', async (_, thunkAPI) => {
  const user = localStorage.getItem('user');
  if (user) {
    thunkAPI.dispatch(fetchGames());
    return JSON.parse(user);
  }
  return thunkAPI.rejectWithValue();
});

export const updateInvoices = createAsyncThunk('auth/updateInvoices', async (invoices, thunkAPI) => {
  const user = thunkAPI.getState().auth.user;
  if (user) {
    const updatedUser = {
      ...user,
      user: {
        ...user.user,
        player: {
          ...user.user.player,
          invoices: invoices
        }
      }
    };

    localStorage.setItem('user', JSON.stringify(updatedUser));
    return updatedUser;
  }
  return thunkAPI.rejectWithValue();
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {user: null, status: 'failed', error: null, emailError: null},
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      state.status = 'failed';
    },
    clearError(state) {
      state.error = null;
    },
    clearEmailVerification: (state) => {
      state.emailValid = null;
      state.emailError = null;
    },
  },
  extraReducers: (builder) => {
    // Логин пользователя
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error.message;
        }
      });


    // Регистрация пользователя
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error.message;
        }
      })

    // проверка логина
    builder
      .addCase(emailVerification.pending, (state) => {
        state.emailError = null;
      })
      .addCase(emailVerification.fulfilled, (state, action) => {
        state.emailValid = action.payload;
      })
      .addCase(emailVerification.rejected, (state, action) => {
        state.emailError = action.payload;
      });
    //проверка и запись данных с LocalStorage
    builder
      .addCase(loadUserFromLocalStorage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(loadUserFromLocalStorage.rejected, (state, action) => {
        state.status = 'failed';
      });
    //обновление invoices
    builder
      .addCase(updateInvoices.fulfilled, (state, action) => {
        state.user = action.payload;
      })
  },
});

export const {logoutUser, clearError, clearEmailVerification} = authSlice.actions;
export default authSlice.reducer;
