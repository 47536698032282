import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import classNames from "classnames";
import s from "./Input.module.scss";
import search from "../../assets/images/search.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactComponent as IsValid} from "../../assets/icons/is-valid.svg";
import {ReactComponent as NotValid} from "../../assets/icons/errorAlert.svg";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {fetchGames, fetchGamesCategory} from "../../redux/slice/gamesSlice";


const Input = (
  {
    children,
    placeholder,
    styled,
    onClick,
    label,
    isSearch = false,
    classWrap,
    type,
    isEye = false,
    error,
    touched,
    isError = true,
    onBlur,
    validEmail,
    onKeyDown,
    forwardedRef,
    categoryId,
    ...props
  }
) => {
  const delayTimeoutRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const classes = classNames(
    s.input,
    {
      [s.primary]: styled === "primary",
    },
  );
  useEffect(() => {
    setInputValue("");
  }, [location.pathname]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    clearTimeout(delayTimeoutRef.current);
    delayTimeoutRef.current = setTimeout(() => {
      props.fetchGames(value, location);
    }, 1000);
  };

  const getErrorIcon = () => {
    if (validEmail && !validEmail.isExist && error !== "Invalid email format") {
      return <IsValid className={s.icon}/>;
    } else if (validEmail || error === "Invalid email format") {
      return <NotValid className={s.icon}/>;
    }
    if (error && (error === "Password is required" || error === "Passwords do not match")) {
      return <NotValid className={s.icon}/>;
    } else if (error) {
      return <IsValid className={s.icon}/>;
    }
    return null;
  }

  const errorIcon = getErrorIcon();

  return (
    <div className={classNames(s.inputBlock, {[classWrap]: classWrap})}>
      <div>
        {label && <label className={s.formLabel}>{label}</label>}
        <input
          value={inputValue}
          className={classes}
          onClick={onClick}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={handleChange}
          type={isEye && showPassword ? "text" : type}
          onKeyDown={onKeyDown}
          ref={forwardedRef}
          {...props}
        >
          {children}
        </input>
        {errorIcon && <div className={classNames(s.iconsValid, {[s.validEmailFalse]: error})}>
          {errorIcon}
        </div>}
        {isEye && (
          <FontAwesomeIcon
            className={s.iconEye}
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        {isSearch && <div className={s.searchIconInInput}><img src={search} alt="search"/></div>}
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const categoryName = ["slots", "roulette", "poker", "dice"];

  return {
    fetchGames: (query, location) => {
      const searchCategory = categoryName.includes(location.pathname.replace("/", ""));
      return searchCategory
        ? dispatch(fetchGamesCategory({
          category_ids: ownProps.categoryId,
          query: query
        }))
        : dispatch(fetchGames({query: query}));
    }
  };
}

export default connect(null, mapDispatchToProps)(Input);