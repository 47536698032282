import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {loginUser} from '../../redux/slice/authSlice';
import MD5 from 'crypto-js/md5';
import Popup from '../Popup/Popup';
import {useNavigate} from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenPopUpLogIn, setOpenPopUpLogIn] = useState(true);

  const handleSubmit = (email, password) => {
    dispatch(loginUser({username: email, password: MD5(password).toString()}));
    handleClosePopup()
    setOpenPopUpLogIn(false);
  };

  const handleOpenPopup = () => {
    setOpenPopUpLogIn(true);
  };

  const handleClosePopup = () => {
    setOpenPopUpLogIn(false);
    navigate('/');
  };

  return (
    <>
      <button onClick={handleOpenPopup}>Open Login Popup</button>
      {isOpenPopUpLogIn && (
        <Popup
          headerName="Login"
          onSubmit={handleSubmit}
          login
          onClose={handleClosePopup}
        />
      )}
    </>
  );
};

export default Login;
