import React from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Bonus from "../Bonus/Bonus";
import bonus from "../../../assets/images/bonus.png"

const Bonus50 = () => {
  const title = "Introducing \"Bonus 50\" Promotion!"
  const label = "We are thrilled to announce our exciting new promotion, \"Bonus 50\", where we're giving away a bonus prize of 50 UAH to all registered customers."
  const registration = "Registration: To participate in the \"Bonus 50\" promotion, simply register as a customer on our platform. It's quick, easy, and completely free!"
  const eligibility = "Eligibility: Once you have successfully registered, you automatically become eligible to receive the bonus prize of 50 UAH."
  const prizeDistribution = "After completing the registration process, we will credit your account with the bonus amount of 50 UAH. You can use this prize money towards your purchases or choose to withdraw it as per our terms and conditions."
  const limitedTimeOffer = "Limited Time Offer: The \"Bonus 50\" promotion is a limited time offer, so make sure to register before the deadline to secure your bonus prize."
  const TermsAndConditions = "Terms and Conditions: Please note that there are certain terms and conditions associated with the \"Bonus 50\" promotion. These terms include eligibility requirements, usage limitations, and withdrawal restrictions. We encourage you to read and understand the complete terms and conditions, which are available on our support."
  const info = "Don't miss out on this fantastic opportunity to receive a bonus prize of 50 UAH! Register now and enjoy the benefits of being a part of our valued customer community."
  const prevClick = "/bonus-500"
  const nextClick = "/persian-bonus"

  const prevBonus = (
    <div>
      Bonus 500
      <div>
        <p className="bonusParagraph">We are excited to present our latest promotion, "Bonus 500," where we are offering
          a generous prize of 500 UAH (Ukrainian hryvnia) to all registered customers who deposit an equal amount into
          their accounts.</p>
      </div>
    </div>
  );
  const nextBonus = (
    <div>
      Persian Bonus
      <div>
        <p className="bonusParagraph">We are delighted to announce our latest promotion, the "Personal Bonus", where you
          have the opportunity to receive a refund of 10% of the total amount you spend.</p>
      </div>
    </div>
  );

  return (
    <div className="container">
      <Breadcrumbs name="Bonus 50"/>
      <Bonus
        img={bonus}
        title={title}
        label={label}
        registration={registration}
        eligibility={eligibility}
        prizeDistribution={prizeDistribution}
        limitedTimeOffer={limitedTimeOffer}
        TermsAndConditions={TermsAndConditions}
        info={info}
        prevBonus={prevBonus}
        nextBonus={nextBonus}
        prevClick={prevClick}
        nextClick={nextClick}
      />

    </div>
  );
};

export default Bonus50;