import React from 'react';
import s from "./Footer.module.scss"
import {Link} from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      <div className={s.footerWrapper}>
        <div className={s.footerTop}>
          <ul className={s.footerLinkLeft}>
            <li>
              <Link to="/term-of-use" onClick={scrollToTop}>
                Tern of use
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" onClick={scrollToTop}>
                Privacy policy
              </Link>
            </li>
            <li>
              <a href="#">Help center</a>
            </li>
          </ul>
        </div>
        <div className={s.footerBottom}>
          <p>Copyright 2023 <a href="#">ALSETA</a> All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;