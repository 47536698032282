import React, {useEffect, useState} from 'react';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import MainContainer from "./components/MainContainer/MainContainer";
import Header from "./components/Header/Header";
import LeftSidebar from "./components/LeftSidebar/LeftSidebar";
import Bonus50 from "./components/Page/Bonus50/Bonus50";
import AllGame from "./components/Page/AllGame/AllGame";
import Bonus500 from "./components/Page/Bonus500/Bonus500";
import LiveCasino from "./components/Page/LiveCasino/LiveCasino";
import Slots from "./components/Page/Slots/Slots";
import PopularsGame from "./components/Page/PopularsGame/PopularsGame";
import RecentViewed from "./components/Page/RecentViewed/RecentViewed";
import MyFavoritesGame from "./components/Page/MyFavoritesGame/MyFavoritesGame";
import Footer from "./components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {fetchGames, fetchGamesCategories} from "./redux/slice/gamesSlice";
import Login from "./components/Login/Login";
import PersianBonus from "./components/Page/PersianBonus/PersianBonus";
import LegalPage from "./components/Page/LegalPage/LegalPage";
import {loadUserFromLocalStorage} from "./redux/slice/authSlice";
import PaymentInformation from "./components/Popup/PaymentInformation/PaymentInformation";

const App = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const {games, status} = useSelector(state => state.games);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const paymentSuccess = searchParams.get('payment_success');
  const payment = paymentSuccess === 'true';
  const token = JSON.parse(localStorage.getItem('user'))?.access_token;

  useEffect(() => {
    if (paymentSuccess !== null && token) {
      navigate(`/payment_success?payment_success=${payment}`);
    }
  }, [paymentSuccess, navigate]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(loadUserFromLocalStorage());
      if (games?.entities?.length === 0) dispatch(fetchGames());
      dispatch(fetchGamesCategories());
    }
  }, [status, games, dispatch]);

  const toggleSidebar = () => setSidebarOpen(prevState => !prevState);


  return (
      <div className="App">
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen}/>
        <div className="content">
          <div className="left-sidebar">
            <LeftSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
          </div>
          <div className="main-content">
            <Routes>
              <Route path="/" element={<MainContainer/>}/>
              <Route path="/bonus-50" element={<Bonus50/>}/>
              <Route path="/bonus-500" element={<Bonus500/>}/>
              <Route path="/persian-bonus" element={<PersianBonus/>}/>
              <Route path="/all-game" element={<AllGame/>}/>
              <Route path="/populars-game" element={<PopularsGame/>}/>
              <Route path="/recent-viewed" element={<RecentViewed/>}/>
              <Route path="/my-favorites" element={<MyFavoritesGame/>}/>
              <Route path="/live-casino" element={<LiveCasino/>}/>
              <Route path="/slots" element={<Slots filter="Slots" name="Slots"/>}/>
              <Route path="/roulette" element={<Slots filter="Roulette" name="Roulette"/>}/>
              <Route path="/poker" element={<Slots filter="Blackjack/Poker" name="Blackjack/Poker"/>}/>
              <Route path="/dice" element={<Slots filter="Lotto" name="Lotto"/>}/>
              <Route path="/privacy-policy" element={<LegalPage title="Privacy Policy"/>}/>
              <Route path="/term-of-use" element={<LegalPage title="Term of use"/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/payment_success" element={<PaymentInformation payment={payment}/>}/>
            </Routes>
            <Footer/>
          </div>
        </div>
      </div>
  );
}
export default App;
