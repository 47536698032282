import axios from "axios";
import env from "../helpers/baseURL";

const agent = axios.create({ baseURL: env.baseURL });

agent.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('user');
    }
    return Promise.reject(error);
  }
);

export default agent;
