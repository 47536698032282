import axios from "./axios"

export const sendPayment = async (id, params, token) => {
  try {
    return await axios.post(`/payments/initialize/payonhost/${id}`, params, {
      headers: {Authorization: `Bearer ${token}`}
    })
  } catch (error) {
    throw error.response.data.message
  }
};

export const updateAccounts = async (token) => {
  try {
    return await axios.get(`/invoices`, {
      headers: {Authorization: `Bearer ${token}`}
    })
  } catch (error) {
    throw error.response.data.message
  }
};