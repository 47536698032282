import React from 'react';
import s from "./Breadcrumbs.module.scss";
import {ReactComponent as Arrow} from "../../assets/icons/arrow-left.svg";

const Breadcrumbs = ({name}) => {
  return (
    <div className={s.breadcrumbsContent}>
      <h1>{name}</h1>
      <a href="/">Home</a>
      <Arrow/>
      {name}
    </div>
  );
};

export default Breadcrumbs;