import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import Button from "../../Button/Button";
import "./SlideGroupGame.scss";
import {useSelector} from "react-redux";
import {randomAutoplaySpeedSlider} from "../../../helpers/randomAutoplaySpeedSlider";

const SlideGroupGame = ({name}) => {
  const games = useSelector(state => state.games.games?.entities);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const status = useSelector(state => state.auth.status);

  useEffect(() => {
    const match = window.matchMedia("(max-width: 576px)");
    const changeSlidesToShow = (e) => {
      setSlidesToShow(e.matches ? 1 : 2);
    };

    match.addListener(changeSlidesToShow);
    changeSlidesToShow(match);

    return () => match.removeListener(changeSlidesToShow);
  }, []);
  const selectRandomGames = (games, count) => {
    const shuffled = [...games].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomGames = games && selectRandomGames(games, 18);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: randomAutoplaySpeedSlider,
    pauseOnHover: true,
    arrows: false,
  };

  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const chunkArray = (array, chunk_size) => {
    let results = [];
    while (array.length) {
      results.push(array.splice(0, chunk_size));
    }
    return results;
  };

  const slides = randomGames ? chunkArray(randomGames, 3) : [];

  return (
    <div className="slider-container-group">
      <div className="slider-header">
        <h2 className="slider-title">{name}</h2>
        <div className="slider-buttons">
          <button type="button" className="prev" onClick={handlePrev}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button type="button" className="next" onClick={handleNext}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slideItems, slideIndex) => (
          <div key={slideIndex} className="slide">
            <div className="slide-row">
              {chunkArray([...slideItems], 3).map((columnItems, columnIndex) => (
                <div key={columnIndex} className="slide-column">
                  {columnItems.map((game, itemIndex) => (
                    <div key={itemIndex} className="slide-item">
                      <div className="slide-background">
                        <div className="slide-photo" style={{
                          backgroundImage: `url(${game.images[0].link})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          width: `96px`,
                          height: `96px`,
                        }}/>
                        <div className="slide-text">{game.name}</div>
                        {status === "succeeded"
                          ? <Button styled="primary" onClick={() => window.open(game.links.play)}>Play</Button>
                          : <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlideGroupGame;
