import React, {useRef, useEffect, useState} from 'react';
import s from './Popup.module.scss';
import Input from "../Input/Input";
import Button from "../Button/Button";
import classNames from "classnames";
import {useFormik} from "formik";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {useDispatch, useSelector} from "react-redux";
import {clearEmailVerification, clearError, emailVerification} from "../../redux/slice/authSlice";
import {Link} from "react-router-dom";

const Popup = ({onClose, onSubmit, name, headerName, login = false}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [validEmail, setValidEmail] = useState(null)
    const popupContentRef = useRef();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const confirmPasswordInputRef = useRef();
    const checkboxRef = useRef();
    const buttonRef = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupContentRef.current && !popupContentRef.current.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    useEffect(() => {
      let countdownTimeout;
      if (formSubmitted && countdown > 0) {
        countdownTimeout = setTimeout(() => setCountdown(countdown - 1), 1000);
      }
      return () => clearTimeout(countdownTimeout);
    }, [formSubmitted, countdown]);

    useEffect(() => {
      if (auth.emailValid) {
        setValidEmail(auth.emailValid);
      }
    }, [auth.emailValid]);

    useEffect(() => {
      return () => {
        dispatch(clearEmailVerification());
      };
    }, []);

    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
        confirmPassword: '',
        termsAccepted: false
      },
      validate: (values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Email is required';
        } else if (
          !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(values.email)
        ) {
          errors.email = 'Invalid email format';
        }
        if (validEmail && validEmail.isExist) {
          errors.email = 'This login is already registered';
        }
        if (!values.password) {
          errors.password = 'Password is required';
        }
        if (!login && values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Passwords do not match';
        }
        if (!login && !values.termsAccepted) {
          errors.termsAccepted = 'You must accept the terms and conditions';
        }
        return errors;
      },
      onSubmit: (values) => {
        onSubmit(values.email, values.password, login);
        if (!login) {
          setFormSubmitted(true);
          setCountdown(120);
        }
      },
    });

    const isPasswordMatch = (!formik.values.password && !formik.values.confirmPassword) || formik.values.password === formik.values.confirmPassword;

    const verificationEmail = () => {
      dispatch(emailVerification({key: formik.values.email}));
    };

    const handleInputKeyDown = (event) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        if (event.target === emailInputRef.current && passwordInputRef.current) {
          passwordInputRef.current.focus();
        } else if (event.target === passwordInputRef.current && confirmPasswordInputRef.current) {
          confirmPasswordInputRef.current.focus();
        } else if (event.target === confirmPasswordInputRef.current) {
          if (formik.values.email && formik.values.password && formik.values.confirmPassword) {
            formik.handleSubmit();
          }
        } else if (event.target === checkboxRef.current) {
          buttonRef.current.focus();
        } else if (event.target === buttonRef.current) {
          buttonRef.current.click();
        }
      }
    };
    const firstErrorKey = Object.keys(formik.errors)[0];
    const firstErrorMessage = formik.errors[firstErrorKey];

    return (
      <div className={s.overlay}>
        <div className={s.popupContent} ref={popupContentRef}>
          <div className={s.head}>
            {formSubmitted && <Close className={s.iconClose} onClick={onClose}/>}
            <h2>{headerName}</h2>
            <p> Or Sign up With </p>
          </div>
          <div className={s.emailBar}>
            <span className={s.bar}></span>
            <span className={s.mainText}>Email</span>
          </div>
          <h2>{name}</h2>
          <p className={s.errorMessage}>{auth?.error}</p>
          {!auth?.error && !formSubmitted
            ? <>
              <Input
                classWrap={s.classNameWrap}
                onChange={formik.handleChange}
                value={formik.values.email.trim().toLowerCase()}
                name="email"
                placeholder="Your Email"
                styled="primary"
                label="Email"
                onBlur={() => {
                  formik.handleBlur("email");
                  if (!login) {
                    verificationEmail();
                  }
                }}
                error={formik.errors.email}
                touched={formik.touched.email}
                validEmail={validEmail}
                onKeyDown={handleInputKeyDown}
                forwardedRef={emailInputRef}
              />
              {login
                ? <Input
                  classWrap={s.classNameWrap}
                  placeholder="Your Password"
                  styled="primary"
                  label="Password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name="password"
                  isEye
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  onKeyDown={handleInputKeyDown}
                  forwardedRef={passwordInputRef}
                  onBlur={() => formik.handleBlur("password")}
                />
                : <>
                  <Input
                    classWrap={s.classNameWrap}
                    placeholder="Your Password"
                    styled="primary"
                    label="Password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    name="password"
                    isEye
                    touched={formik.touched.password}
                    error={formik.errors.password}
                    onKeyDown={handleInputKeyDown}
                    forwardedRef={passwordInputRef}
                    onBlur={() => formik.handleBlur("password")}
                  />
                  <Input
                    classWrap={s.classNameWrap}
                    placeholder="Confirm Your Password"
                    styled="primary"
                    label="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    isEye
                    onBlur={() => formik.handleBlur("confirmPassword")}
                    error={formik.errors.confirmPassword}
                    touched={formik.touched.confirmPassword}
                    onKeyDown={handleInputKeyDown}
                    forwardedRef={confirmPasswordInputRef}
                  />
                </>}
              {firstErrorKey && <div className={classNames(s.error, {[s.visibleError]: formik.touched && formik.errors})}>
                {'*' + firstErrorMessage}
              </div>}
              <div className={s.checkBox}>
                {!login && (
                  <div className={s.formCheck}>
                    <input
                      className={s.formCheckInput}
                      type="checkbox"
                      onChange={formik.handleChange}
                      checked={formik.values.termsAccepted}
                      name="termsAccepted"
                      onKeyDown={handleInputKeyDown}
                      ref={checkboxRef}
                    />
                    <label className={s.formCheckLabel} htmlFor="flexCheckDefault22">
                      I confirm I am over 18 years old I have read
                      <Link to="/term-of-use" className={s.textBase} onClick={onClose}>
                        terms of service
                      </Link>
                    </label>
                    <div
                      className={classNames(s.error, {[s.visibleError]: formik.touched.termsAccepted && formik.errors.termsAccepted})}>
                      {formik.errors.termsAccepted}
                    </div>
                  </div>
                )}
              </div>
              <div className="popup-buttons">
                <Button
                  styled="primary"
                  onClick={formik.handleSubmit}
                  className={s.buttonStyle}
                  onKeyDown={handleInputKeyDown}
                  ref={buttonRef}
                >
                  {login ? "Login" : "Create Account"}
                </Button>
              </div>
              <div className={s.head}>
                <p>
                  Already have an account?
                  <a href="#" className={s.textBase}>
                    Login
                  </a>
                </p>
              </div>
            </>
            :
            !auth?.error && auth?.status === "succeeded" ? <>
                <p>Please check your email for further registration (check your spam email to resend a second email)</p>
                <div className={s.timeOut}>Resend email in {countdown} seconds</div>
                <Button styled={countdown === 0 ? "primary" : "disabled"}
                        onClick={() => {
                          if (countdown === 0) {
                            setFormSubmitted(false);
                            formik.handleSubmit();
                          }
                        }}
                        disabled={countdown !== 0}
                >
                  Resend Email
                </Button>
              </> :
              <Button styled="primary" onClick={() => {
                setFormSubmitted(false)
                dispatch(clearError())
              }}>
                Back to
              </Button>
          }
        </div>
      </div>
    );
  }
;

export default Popup;
