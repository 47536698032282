import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import photo2 from "../../../assets/banner/banner-shape2.png";
import photo3 from "../../../assets/banner/banner-shape.png";
import './SliderCustom.scss';
import Button from "../../Button/Button";
import s from "../../Header/Header.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Popup from "../../Popup/Popup";
import {registerUser} from "../../../redux/slice/authSlice";
import MD5 from "crypto-js/md5";

const SliderCustom = () => {
    const dispatch = useDispatch();
    const status = useSelector(state => state.auth.status);
    const [isOpenPopUpReg, setOpenPopUpReg] = useState(false);

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      speed: 2000,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
    };

    const handleSubmit = (email, password) => {
      dispatch(registerUser({
        key: email,
        password: MD5(password).toString(),
        provider: "email",
        user_type: "player"
      }));
    }

    const handleCloseSignUpPopup = () => {
      setOpenPopUpReg(false);
    };

    return (
      <div className="wrapSliderStyle banner-items">
        <div className="banner-items">
          <Slider {...settings}>
            <div>
              <div className="blockImg">
                <img src={photo3} alt="Slide 1"/>
              </div>
              <div className="slider-content">
                <h1>
                  PERSIAN BONUS
                </h1>
                <h2 className="text-base">
                  Cashback
                </h2>
                <h3>
                  up to 10%!
                </h3>
                <div className="slider-buttons">
                  {status !== "succeeded" &&
                    <Button onClick={() => setOpenPopUpReg(true)} styled="primary" className={s.signupButton}>
                      Create Account
                    </Button>}
                  <Button styled="secondary" className={s.loginButton}>
                    <Link to="/persian-bonus">
                      go to Persian Bonus page
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="blockImg">
                <img src={photo2} alt="Slide 2"/>
              </div>
              <div className="slider-content">
                <div className={"slider-content-title"}>
                  <h2>
                    Hi Bonus Without deposit
                  </h2>
                  <h4>
                    Take free first bonus
                  </h4>
                </div>
                <div className="slider-buttons">
                  {status !== "succeeded" &&
                    <Button onClick={() => setOpenPopUpReg(true)} styled="primary" className={s.signupButton}>
                      Create Account
                    </Button>}
                  <Button styled="secondary" className={s.loginButton}>
                    <Link to="/bonus-50">
                      go to Bonus 50 page
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="blockImg">
                <img src={photo3} alt="Slide 3"/>
              </div>
              <div className="slider-content">
                <h2>
                  Hi bonus 500
                </h2>
                <h4>
                  after first deposit
                </h4>
                <div className="slider-buttons">
                  {status !== "succeeded" &&
                    <Button onClick={() => setOpenPopUpReg(true)} styled="primary" className={s.signupButton}>
                      Create Account
                    </Button>}
                  <Button styled="secondary" className={s.loginButton}>
                    <Link to="/bonus-500">
                      go to Bonus 500 page
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        {isOpenPopUpReg &&
          <Popup
            headerName="Sign up"
            onSubmit={handleSubmit}
            onClose={handleCloseSignUpPopup}
          >
          </Popup>
        }
      </div>
    );
  }
;

export default SliderCustom;
