import React from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useSelector} from "react-redux";
import s from "../PopularsGame/PopularsGame.module.scss";
import {sizeBackgroundImage} from "../../../helpers/sizeBackgroundImage";
import Button from "../../Button/Button";

const RecentViewed = () => {
  const authStatus = useSelector(state => state.auth.status);
  const games = useSelector(state => state.games.games?.entities);
  const selectRandomGames = (games, count) => {
    const shuffled = [...games].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };
  const randomGames = games && selectRandomGames(games, 18);
  return (
    <div className="container">
      <Breadcrumbs name="Recent Viewed"/>
      <div className={s.elementsContainer}>
        {randomGames && randomGames.map((game, index) => {
          const selectedImage = sizeBackgroundImage(game.images);
          return (
            <div key={index} className={s.element}
                 style={{
                   backgroundImage: `url(${selectedImage?.link})`,
                   backgroundSize: 'cover',
                   backgroundPosition: 'center',
                   width: `${selectedImage?.width}px`,
                   height: `${selectedImage?.height}px`,
                 }}>
              <div className={s.elementHover}>
                {authStatus === "succeeded"
                  ? <>
                    <Button styled="primary" onClick={() => window.open(game.links.play)}>Play</Button>
                    <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button></>
                  : <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentViewed;