import React from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";

const LiveCasino = () => {
  return (
    <div className="container">
      <Breadcrumbs name="Live Casino"/>
    </div>
  );
};

export default LiveCasino;