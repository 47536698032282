import React, {useEffect, useRef, useState} from 'react';
import s from './Header.module.scss';
import logo from '../../assets/images/logo/logo.png';
import ironPng from '../../assets/images/iron.png';
import bronzePng from '../../assets/images/bronze.png';
import goldPng from '../../assets/images/gold.png';
import platinumPng from '../../assets/images/platinum.png';
import vipPng from '../../assets/images/vip.png';
import chcLogo from '../../assets/images/chc_logo.png';
import wallet from '../../assets/images/walllet.png';
import logoSmall from '../../assets/images/logo/logo-small.png';
import telegram from '../../assets/images/tele.png';
import Button from '../Button/Button';
import {ReactComponent as Home} from '../../assets/icons/home.svg';
import {ReactComponent as UserIcons} from "../../assets/icons/userAccaunt.svg";
import {ReactComponent as UserAccount} from "../../assets/icons/account-33.svg";
import {ReactComponent as Exit} from "../../assets/icons/exit-2860.svg";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import referral from '../../assets/images/referal.png';
import search from '../../assets/images/search.png';
import Input from '../Input/Input';
import Popup from "../Popup/Popup";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loginUser, logoutUser, registerUser} from "../../redux/slice/authSlice";
import MD5 from 'crypto-js/md5';
import Divider from "../Divider/Divider";
import BottomHeader from "../BottomHeader/BottomHeader";
import classNames from "classnames";
import TopUpAccountPopUp from "../Popup/TopUpAccountPopUp/TopUpAccountPopUp";
import env from "../../helpers/baseURL";
import WithdrawPopUp from "../Popup/WithdrawPopUp/WithdrawPopUp";

const Header = ({toggleSidebar, isSidebarOpen}) => {
  const dispatch = useDispatch();
  const authStatus = useSelector(state => state?.auth?.status);
  const user = useSelector(state => state.auth?.user?.user);
  const [isOpenPopUpReg, setOpenPopUpReg] = useState(false);
  const [isOpenPopUpLogIn, setOpenPopUpLogIn] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const authLocalStorage = JSON.parse(localStorage.getItem('user'))?.access_token
  const userPopupRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useLocation()
  const categories = useSelector(state => state.games?.categories);
  const categoryId = categories?.filter(category => category.name.toLowerCase() === navigate.pathname.replace("/", ""))[0]?.id;
  const [popup, setPopup] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);

  const positionIcons = {
    iron: ironPng,
    bronze: bronzePng,
    gold: goldPng,
    platinum: platinumPng,
    vip: vipPng
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (authStatus === 'succeeded' || authLocalStorage) {
      setOpenPopUpLogIn(false);
      setOpenPopUpReg(false);
    }
  }, [authStatus]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (userPopupRef.current && !userPopupRef.current.contains(event.target)) {
        setShowUserPopup(false);
      }
    }

    userPopupRef.current && document.addEventListener("mousedown", handleClickOutside);
    return () => {
      userPopupRef.current && document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userPopupRef]);

  const handleSubmit = (email, password, isLogin) => {
    if (isLogin) {
      dispatch(loginUser({username: email, password: MD5(password).toString()}));
    } else {
      dispatch(registerUser({
        key: email,
        password: MD5(password).toString(),
        provider: "email",
        user_type: "player"
      }));
    }
  };

  useEffect(() => {
    if (authStatus === 'succeeded' || authLocalStorage) {
      setOpenPopUpLogIn(false);
      setOpenPopUpReg(false);
    }
  }, [authStatus]);

  const handleLogout = () => {
    setShowUserPopup(false)
    localStorage.removeItem('user');
    dispatch(logoutUser());
  };

  const handleCloseSignUpPopup = () => {
    setOpenPopUpReg(false);
  };
  const handleUserIconsClick = (event) => {
    event.stopPropagation();
    setShowUserPopup(prev => !prev);
  };

  return (
    <div className={s.wrap}>
      <div className={s.headerTopWrapper}>
        <div className={s.logo}>
          {windowWidth < 1000 && <BottomHeader isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>}
          <Link to="/">
            <img className={classNames({[s.imgSmall]: windowWidth < 1000})} src={windowWidth < 1000 ? logoSmall : logo}
                 alt="logo"/>
          </Link>
        </div>
        <div className={s.middleReferralStyle}>
          <ul className={s.middleReferralStyleBlock}>
            <li className={s.homeIconStyle}>
              <Home/>
            </li>
            <li className={s.referralStyle}>
              <img className={s.referalStyleIcon} src={referral} alt="referal"/>
              Referal
            </li>
            <li className={s.searchStyle}>
              <img src={search} alt=""/>
            </li>
            <li className={s.searchInputStyle}>
              <Input placeholder="Search........." styled="primary" categoryId={categoryId} isSearch isError={false}/>
            </li>
          </ul>
        </div>

        <div className={s.searchInputStyleSmall}>
          <Input placeholder="Search........." styled="primary" categoryId={categoryId} isSearch isError={false}/>
        </div>

        <ul className={s.loinStyle}>
          <li className={s.iconMessage}>
            <a href={env.telegramUrl} target="_blank" rel="noopener noreferrer">
              <img src={telegram} alt="telegram"/>
            </a>
          </li>
          <li className={s.buttonBlockStyle}>
            {authStatus === 'succeeded' && authLocalStorage ? (
              <>
                <UserIcons onClick={handleUserIconsClick}/>
                {showUserPopup &&
                  <ul className={s.dropdownMenu} ref={userPopupRef}>
                    <li className={s.selectUser}>
                      <UserAccount/>
                      <div className={s.userStyle}>
                        <h4 className={s.referralStyle}>{user?.first_name.split("@").shift()}</h4>
                        <p>{user?.last_name}</p>
                        <p>{user?.logins[0]?.login_key}</p>
                      </div>
                      <span className={s.closeIconStyle} onClick={() => setShowUserPopup(false)}><Close/></span>
                    </li>
                    <li className={classNames(s.selectUser, s.selectUserUppercase)}>
                      <img src={positionIcons[user.player.position] || ironPng} alt="IronPng"/>{user.player.position}
                    </li>
                    <li className={s.selectUserTitleText}>
                      Accounts
                    </li>
                    <Divider marginBottom={10}/>
                    <li className={s.selectUserAccounts}>
                      {user.player.invoices.length
                        ? user.player.invoices.map(inv => {
                          return (
                            <div className={s.invoices}>
                              <img src={chcLogo} alt="chcLogo"/>
                              <div className={s.invoices}>
                                <span> {inv?.account && inv.account.split('').map((digit, i, arr) => i % 2 && i < arr.length - 1 ? digit + '-' : digit).join('')}</span>
                                <span className={s.referralStyle}> {inv.amount === 0 ? "0.00" : inv.amount}</span>
                              </div>
                            </div>
                          )
                        })
                        : <div className={s.invoices}>
                          <span className={s.notInvoices}>Not Accounts</span>
                        </div>}
                    </li>
                    <li className={s.selectUserTitleText}>
                      Payments
                    </li>
                    <Divider marginBottom={10}/>
                    <li className={s.wallet} onClick={() => {
                      if (user.player?.invoices?.length) setCurrentAccount(user.player.invoices[0])
                      setPopup("TopUpAccount")
                      setShowUserPopup(false)
                    }}>
                      <img src={wallet} alt="wallet"/> Top up
                    </li>
                    <li className={s.wallet} onClick={() => {
                      if (user.player?.invoices?.length) setCurrentAccount(user.player.invoices[0])
                      setPopup("Withdraw")
                      setShowUserPopup(false)
                    }}>
                      <img src={wallet} alt="withdraw"/> Withdraw
                    </li>
                    <Divider marginBottom={10} marginTop={10}/>
                    <li className={s.selectUserTitle} onClick={handleLogout}>
                      <Exit/>
                      Log out
                    </li>
                  </ul>
                }
              </>
            ) : (
              <>
                <Button styled="secondary" onClick={() => setOpenPopUpLogIn(true)} className={s.loginButton}>
                  Log in
                </Button>
                <Button styled="primary" onClick={() => setOpenPopUpReg(true)} className={s.signupButton}>
                  Sign Up
                </Button>
              </>
            )}
          </li>
        </ul>
      </div>
      {popup === "TopUpAccount" &&
        <TopUpAccountPopUp data={user} currentAccount={currentAccount} onClose={() => setPopup(null)}/>
      }
      {popup === "Withdraw" &&
        <WithdrawPopUp data={user} currentAccount={currentAccount} onClose={() => setPopup(null)}/>
      }
      {isOpenPopUpReg &&
        <Popup
          headerName="Sign up"
          onSubmit={handleSubmit}
          onClose={handleCloseSignUpPopup}
        >
        </Popup>
      }
      {isOpenPopUpLogIn &&
        <Popup
          headerName="Login"
          onSubmit={handleSubmit}
          login
          onClose={() => setOpenPopUpLogIn(false)}
        />
      }
    </div>
  );
};

export default Header;
