import {configureStore} from '@reduxjs/toolkit';
import gamesReducer from '../redux/slice/gamesSlice';
import authReducer from '../redux/slice/authSlice';


export default configureStore({
  reducer: {
    auth: authReducer,
    games: gamesReducer,
  },
});
