import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import services from '../../services';

export const fetchGames = createAsyncThunk('games/fetchGames', async (params = {}, thunkAPI) => {
  const {query = undefined, isLazyLoading = false} = params;
  const response = await services.getAllGames(query, thunkAPI.dispatch);
  return {data: response.data, isLazyLoading};
});

export const fetchGamesCategories = createAsyncThunk('games/fetchGamesCategories', async (_, thunkAPI) => {
  const response = await services.getGamesCategories(thunkAPI.dispatch);
  return response.data;
});

export const fetchGamesCategory = createAsyncThunk('games/fetchGamesCategory', async (params, thunkAPI) => {
  const response = await services.getAllGames(params, thunkAPI.dispatch);
  return response.data;
});

const gamesSlice = createSlice({
  name: 'games',
  initialState: {
    games: JSON.parse(localStorage.getItem('game')) || {count: 0, entities: [], per_page: 30},
    categoryFilters: [],
    categories: [],
    gameCategories: JSON.parse(localStorage.getItem('gameCategories')) || {},
    status: 'idle',
    error: null
  },
  reducers: {
    clearCategoryFilters: (state) => {
      state.categoryFilters = [];
    },
    updateGameCategories: (state, action) => {
      state.categoryFilters = action.payload;
    },
    updateGameCategory: (state, action) => {
      const {category, categoryId} = action.payload;
      state.gameCategories[categoryId] = category;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchGames.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchGames.fulfilled, (state, action) => {
          if (action.payload.isLazyLoading) {
            state.games = {
              ...state.games,
              entities: [...(state.games.entities || []), ...action.payload.data.entities]
            };
          } else {
            state.games = action.payload.data;
          }
          state.status = 'succeededGames';
          localStorage.setItem('game', JSON.stringify(state.games));
        })
        .addCase(fetchGames.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(fetchGamesCategories.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchGamesCategories.fulfilled, (state, action) => {
          state.status = 'succeededCategories';
          state.categories = action.payload;
        })
        .addCase(fetchGamesCategories.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(fetchGamesCategory.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchGamesCategory.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.categoryFilters = action.payload;

          const {category_ids} = action.meta.arg;
          state.gameCategories = {
            ...state.gameCategories,
            [category_ids]: action.payload,
          };
        })
        .addCase(fetchGamesCategory.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });

  },
});
export const {clearCategoryFilters, updateGameCategories, updateGameCategory} = gamesSlice.actions;
export default gamesSlice.reducer;
