import React, {useEffect} from 'react';
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import s from "../AllGame/AllGame.module.scss";
import {sizeBackgroundImage} from "../../../helpers/sizeBackgroundImage";
import Button from "../../Button/Button";
import {fetchGamesCategory, updateGameCategories} from "../../../redux/slice/gamesSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingCustom from "../../loadingStates/LoadingCustom/LoadingCustom";

const Slots = ({filter, name}) => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.games?.categories);
  const categoryId = categories?.filter(category => category.name === filter)[0]?.id;
  const category = useSelector(state => state.games?.categoryFilters);
  const filteredCategory = useSelector(state => state.games?.gameCategories[categoryId]);
  const authStatus = useSelector(state => state.auth.status);

  useEffect(() => {
    if (!filteredCategory?.entities?.length) {
      dispatch(fetchGamesCategory({category_ids: categoryId}));
    } else {
      dispatch(updateGameCategories(filteredCategory));
    }
  }, [filter]);

  return (
      <div className="container">
        <Breadcrumbs name={name}/>
        <div className={s.elementsContainer}>
          {category?.entities
              ? category.entities.map((game, index) => {
                const selectedImage = sizeBackgroundImage(game.images);
                return (
                    <div key={index} className={s.element}
                         style={{
                           backgroundImage: `url(${selectedImage?.link})`,
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           width: `${selectedImage?.width}px`,
                           height: `${selectedImage?.height}px`,
                         }}>
                      <div className={s.elementHover}>
                        {authStatus === "succeeded"
                            ? <>
                              <Button styled="primary" onClick={() => window.open(game.links.play)}>Play</Button>
                              <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button></>
                            : <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>}
                      </div>
                    </div>
                );
              })
              : <LoadingCustom/>}
        </div>
      </div>
  );
};

export default Slots;