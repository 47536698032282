import React from 'react';
import s from "../LeftSidebar.module.scss";
import game from "../../../assets/images/joystick.png";
import g9 from "../../../assets/images/fire.png";
import g6 from "../../../assets/images/clock.png";
import {Link} from 'react-router-dom';

const FilterMenu = ({toggleSidebar}) => {
  return (
    <ul className={s.menuFilter}>
      <Link to="/all-game" onClick={toggleSidebar}>
        <li>
          <img src={game} alt=""/>
          <span>All Game</span>
        </li>
      </Link>
      <Link to="/populars-game" onClick={toggleSidebar}>
        <li>
          <img src={g9} alt=""/>
          <span>Populars Game</span>
        </li>
      </Link>
      <Link to="/recent-viewed" onClick={toggleSidebar}>
        <li>
          <img src={g6} alt=""/>
          <span>Recent Viewed</span>
        </li>
      </Link>
    </ul>
  );
};

export default FilterMenu;
