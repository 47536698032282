import React, {useEffect, useRef} from 'react';
import s from "./PaymentInformation.module.scss";
import Button from "../../Button/Button";
import {useNavigate} from "react-router-dom";
import {updateAccounts} from "../../../services/payment";
import {useDispatch} from "react-redux";
import {updateInvoices} from "../../../redux/slice/authSlice";

const PaymentInformation = ({payment}) => {
  const dispatch = useDispatch();
  const popupContentRef = useRef();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (payment) {
      updateAccounts(user?.access_token).then((res) => {
        if (res?.data) {
          dispatch(updateInvoices(res.data));
        }
      });
    }
  }, [])

  return (
    <div className={s.overlay}>
      <div className={s.popupContent} ref={popupContentRef}>
        {payment
          ? <div className={s.successTrue}>Payment successful</div>
          : <div className={s.successFalse}>Refill error occurred</div>}
        <Button onClick={() => navigate('/')}>Close</Button>
      </div>
    </div>
  );
};

export default PaymentInformation;