import React from "react";
import s from "./Table.module.scss";
import classNames from "classnames";
import MySkeleton from "../MySkeleton/MySkeleton";

const Table = ({length = 0}) => {
  const getColumns = () => {
    let data = [];
    for (let i = 0; i < length; i++) {
      data.push(
        <span
          className={s.inline}
          style={{
            width: length > 6 ? `${100 / length - 3}%` : `${100 / length}%`,
          }}
        >
         <MySkeleton className={s.text}/>
        </span>
      );
    }
    return data;
  };
  return (
    <ul className={classNames(s.rows, {[s.large]: length > 6})}>
      <li>{getColumns(0)}</li>
      <li>{getColumns(1)}</li>
      <li>{getColumns(2)}</li>
      <li>{getColumns(3)}</li>
    </ul>
  );
};

export default Table;
