import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import Button from "../../Button/Button";
import "./SliderTypeGame.scss"
import {useSelector} from "react-redux";
import {sizeBackgroundImage} from "../../../helpers/sizeBackgroundImage";
import {randomAutoplaySpeedSlider} from "../../../helpers/randomAutoplaySpeedSlider";

const SliderTypeGame = ({name}) => {
  const games = useSelector(state => state.games.games?.entities);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const status = useSelector(state => state.auth.status);

  useEffect(() => {
    const match = window.matchMedia("(max-width: 576px)");
    const changeSlidesToShow = (e) => {
      setSlidesToShow(e.matches ? 1 : 3);
    };

    match.addListener(changeSlidesToShow);
    changeSlidesToShow(match);

    return () => match.removeListener(changeSlidesToShow);
  }, []);

  const selectRandomGames = (games, count) => {
    const shuffled = [...games].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomGames = games && selectRandomGames(games, 18);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    speed: 2000,
    slidesToScroll: 1,
    rows: 2,
    autoplay: true,
    autoplaySpeed: randomAutoplaySpeedSlider,
    pauseOnHover: true,
    arrows: false,
    cssEase: "linear",
    gap: 10,
  };


  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="slider-game-container pb-60 pt-60">
      <div className="slider-header">
        <h2 className="slider-title">{name}</h2>
        <div className="slider-buttons">
          <button type="button" className="prev" onClick={handlePrev}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button type="button" className="next" onClick={handleNext}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {randomGames && randomGames.map((game, index) => {
          const selectedImage = sizeBackgroundImage(game.images);
          return (
            <div className="slide-item blockImages" key={index}>
              <div
                className="slide-background"
                style={{
                  backgroundImage: `url(${game.images.find(img => img.name === 'large').link})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: `${selectedImage?.width}px`,
                  height: `${selectedImage?.height}px`,
                }}
              >
                {status === "succeeded"
                  ? <div className="blockNameStyle">
                    <Button styled="primary" onClick={() => window.open(game.links.play)}>Play</Button>
                    <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>
                  </div>
                  : <Button styled="primary" onClick={() => window.open(game.links.demo)}>Demo</Button>}
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  );
};

export default SliderTypeGame;
